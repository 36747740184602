import React, { useState, useEffect, useRef, useCallback } from 'react';
import logo from '../../assets/Logo.svg';
import ball1 from '../../assets/Purple.svg'; 
import ball2 from '../../assets/Yellow.svg';
import ball3 from '../../assets/Green.svg';
import ball4 from '../../assets/Pink.svg';
import { ReactComponent as JoinStreamIcon } from '../../assets/Play icon.svg';
import { ReactComponent as DiagArrow } from '../../assets/DiagArrow.svg';



const Countdown = ({ targetDate, timezone }) => {
  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const difference = +targetDate - +now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  }, [targetDate]);

  const padWithZero = (number) => {
    return number < 10 ? `0${number}` : number;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isVideoComplete, setIsVideoComplete] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [isStreamButtonClicked, setIsStreamButtonClicked] = useState(false); 
  const videoRef = useRef(null);

  // Refs for each ball
  const ball1Ref = useRef(null);
  const ball2Ref = useRef(null);
  const ball3Ref = useRef(null);
  const ball4Ref = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  const isCountdownComplete = Object.values(timeLeft).every(value => value === 0);
  const now = new Date();
  const eventDate = targetDate; // Directly use the passed prop
  const timeUntilEvent = eventDate.getTime() - now.getTime();

  const isBeforeEvent = timeUntilEvent > 0;
  const isOnEventDay = now.toDateString() === eventDate.toDateString();
  const isTenMinutesLeft = timeUntilEvent <= 10 * 60 * 1000 && timeUntilEvent > 0;



  const requestFullScreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    if (isCountdownComplete && videoRef.current) {
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setShowPlayButton(false);
            setIsStreamButtonClicked(true);
          })
          .catch(() => {
            setShowPlayButton(true);
          });
      }
    }
  }, [isCountdownComplete]);

  const handleVideoEnd = () => {
    setIsVideoComplete(true);
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      exitFullScreen();
    }
  };

  const handleRewatch = () => {
    setIsVideoComplete(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      requestFullScreen(videoRef.current);
    }
  };

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setShowPlayButton(false);
      setIsStreamButtonClicked(true);
      requestFullScreen(videoRef.current);
    }
  };

  const handleJoinStreamClick = () => {
    setIsStreamButtonClicked(true);
  };
  const handleWorkvivo = () => {
    window.open('https://phmg.workvivo.com/pages/360/eot', '_blank');
  };

  // Move balls away from mouse
  const handleMouseMove = (e) => {
    const balls = [ball1Ref.current, ball2Ref.current, ball3Ref.current, ball4Ref.current];
    const mouseX = e.clientX;
    const mouseY = e.clientY;
  
    balls.forEach((ball) => {
      if (ball) {
        const ballRect = ball.getBoundingClientRect();
        const ballCenterX = ballRect.left + ballRect.width / 2;
        const ballCenterY = ballRect.top + ballRect.height / 2;
  
        const distanceX = ballCenterX - mouseX;
        const distanceY = ballCenterY - mouseY;
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
  
        const maxRange = 300;  // Increase the range of interaction
        const maxMove = 50;    // Max distance a ball can move from its original position
  
        if (distance < maxRange) {
          // Move based on proximity, scaled by how close the mouse is (closer = more movement)
          const proximityFactor = (maxRange - distance) / maxRange;  // Scale from 0 (far) to 1 (close)
          const moveX = (distanceX / distance) * maxMove * proximityFactor;
          const moveY = (distanceY / distance) * maxMove * proximityFactor;
  
          // Apply the transform for smooth movement
          ball.style.transform = `translate(${moveX}px, ${moveY}px)`;
        } else {
          // Reset the transform when far away
          ball.style.transform = 'translate(0, 0)';
        }
      }
    });
  };
  

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const timerComponents = Object.keys(timeLeft).map(interval => (
    <span key={interval} className="countdown-element">
      <p className="countdown-value ivory-font">{padWithZero(timeLeft[interval])}</p>
      <p className="countdown-label ivory-font"><em className='ivory-font'>{interval.slice(0, 3)}s</em></p>
    </span>
  ));
  // const formatTime = (date) => {
  //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  // };

  // {formatTime(targetDate)}

  return (
    <div className="countdown-container">
      <div className="floating-background">
        <img ref={ball1Ref} src={ball1} alt="ball-1" className="floating-ball ball-1" />
        <img ref={ball2Ref} src={ball2} alt="ball-2" className="floating-ball ball-2" />
        <img ref={ball3Ref} src={ball3} alt="ball-3" className="floating-ball ball-3" />
        <img ref={ball4Ref} src={ball4} alt="ball-4" className="floating-ball ball-4" />
      </div>

      <div className="header">
        <div >
          <img className="logo" alt="phmg logo" src={logo} />
        </div>
        <p className='ivory-font'></p>
      </div>

      {!isCountdownComplete ? (
        <>
      <h5 className="event ivory-font"><em className='event-time ivory-font'>Big news</em> is taking shape</h5>
      <div className="content">
            {isBeforeEvent && !isOnEventDay && (
              <div className='main-content-container'>
                <h1 className='main-large-text'>PHMG is evolving,<br/>and <em className='main-large-text-italic'>you</em> are part of it.</h1>
                <br />
                <p style={{marginTop: '32px'}}>Join us on <strong>Tuesday, April 8 at {timezone}</strong> to discover how we’re shaping a new future.</p>
              </div>
            )}

        {isOnEventDay &&   <div className='main-content-container'>
          <h1 className='main-large-text'>Starting soon...</h1>
          </div>}

        {isOnEventDay && isTenMinutesLeft && (
          <div className="countdown">{timerComponents}</div>
        )}

        {isOnEventDay && !isStreamButtonClicked && (
            <button className="join-stream-button" onClick={handleJoinStreamClick}>
                <JoinStreamIcon className="join-stream-icon" />
                Start stream when ready
            </button>
        )}

        {isStreamButtonClicked && (
          <button className="waiting-message">Waiting for the stream to start...</button>
        )}
        </div>
        <p className="footer ivory-font">Make <em className='ivory-font'>Your</em> Mark</p>
      </>
      ) : (<>
        {!isVideoComplete && (
          <div className='content-container'>
                <div className="dark-overlay" />
            <div className='video-container'>
              <video
                ref={videoRef}
                className='video'
                controls
                onEnded={handleVideoEnd}
                src="https://phmg-styles.s3.eu-west-2.amazonaws.com/videos/Master+Presentation+1080p.mp4"
              />
              {showPlayButton && (
                <div className="overlay">
                  <button className="rewatch-button" onClick={handlePlayButtonClick}>Play</button>
                </div>
              )}
            </div>
          </div>
          )}
      </>
      )}

      {isVideoComplete && (
         <>
         <h5 className="event ivory-font"><em className='event-time ivory-font'>Big news</em> is taking shape</h5>
         <div className="content">
           <div className='main-content-container'>
             <h1 className='main-large-text'>PHMG is now <em className='main-large-text-italic'>proudly</em><br/>Employee owned.</h1>
             <br />
             <p>A new chapter. A shared future. All powered by you.</p>
           </div>

  


         <button className="join-stream-button" style={{margin: "30px auto"}}onClick={handleRewatch}>
             <JoinStreamIcon className="join-stream-icon" />
             Watch our launch video
         </button>
         <p style={{margin: '45px auto', borderTop: '2px solid black', width: "30px"}}></p>

         <p style={{marginTop: '32px'}}>Ready to dig deeper? Check out the Workvivo Hub.</p>

       <button className="workvivo-button" style={{marginTop: '30px'}} onClick={handleWorkvivo}>Workvivo EOT hub<DiagArrow className="join-stream-icon" />
       </button>
     
     </div>
     <p className="footer ivory-font">Make <em className='ivory-font'>Your</em> Mark</p>
   </>
      )}
    </div>
  );
};

export default Countdown;
